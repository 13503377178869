<template>
  <div>
    <div class="container">
      <div class="row m-12 justify-content-center align-items-center">
        <div class="row-11 align-content-center justify-content-center" style="{display:inline-block}">
          <div v-if="data_loaded">

            <h3>
              <editable-text @save-edit="saveTitleEdit" :bunchId="bunch_id" :originalTitle="bunch_name" />
            </h3>
          </div>
          <div v-else>
            <h3>
              Загружаем...
            </h3>
          </div>
        </div>


        <div v-if="data_loaded" class="col-1 align-content-center justify-content-center vs-con-loading__container"
          id="div-with-loading">
          <download-cloud-icon v-b-tooltip.hover.bottom title="Скачать выгрузку сообщений" @click="getExcel()" />
        </div>

        <!-- <div class="col-1 align-content-center justify-content-center">

          <span>
            <a href="javascript:void(0)" v-b-modal.HelpForm data-toggle="modal" data-target="#HelpForm"
              style="border: none;" class="text-primary">
              <help-circle-icon class="fea icon-sm"></help-circle-icon>
            </a>
          </span>
          <b-modal id="HelpForm" title="Справка по сервису" centered button-size="sm" size="lg" ok-only>
            <div class="bg-white p-1 rounded box-shadow">
              <div class="text-muted mb-2" style="font-size: small;">
                <p>
                  На данной странице можно увидеть историю развития сюжета.
                </p>

                <p>
                  В шапке располагаются текущее названию сюжета, время когда он был последний раз обновлен,
                  пользовательское и автоматическое описание сюжета.
                </p>
                <p>
                  Название сюжета и пользовательское описание можно отредактировать при нажатии на них.
                </p>

                <p>
                  При нажатии на кнопку "Показать примеры сообщений" можно посмотреть примеры собщений в сюжете.
                </p>

                <p>
                  Ниже в таблице представлена вся цепочка связанных кластеров - дата создания и название. Кластера
                  считаем связанными если у них пересекаются больше 50% сообщений. "Несодержательные" кластеры в учет не
                  принимаются.
                </p>

                <p>
                  Количество сообщений в кластере на опеределенный момент времени также отражен на графике. Важно
                  помнить что мы движемся с окном в 24 часа - поэтому число на зеленом графике показывает сумму
                  сообщений за последние 24 часа.
                  Разбивка непосредственно по часам представлена на синем графике - это разбивка всех уникальных
                  сообщений всех связанных кластеров по часам.
                </p>
                <p>
                  На графике отражены последние 3 суток жизни сюжета.
                </p>
              </div>
            </div>
          </b-modal>
        </div> -->
        <div v-if="data_loaded" class="row">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Автоматическое описание</h5>
                <p class="card-text">{{ bunch_data.bunch_auto_summary }}</p>
              </div>
              <div class="card-body">
                <h5 class="card-title">Пользовательское описание</h5>
                <editable-text @save-edit="saveSummaryEdit" :bunchId="bunch_id"
                  :originalTitle="['', ' ', null, undefined].includes(bunch_data.bunch_user_summary) ? 'Нажмите, чтобы добавить описание' : bunch_data.bunch_user_summary" />
              </div>

              <div class="card-body">
                <h5 class="card-title">Статистика</h5>
                <ul>
                  <li> Сообщения в сюжете - с {{ prettyDt(bunch_data.oldest_message_time) }} по
                    {{ prettyDt(bunch_data.newest_message_time) }}</li>
                  <li> Кластеры в сюжете - с {{ prettyDt(bunch_data.oldest_cluster_time) }} по
                    {{ prettyDt(bunch_data.newest_cluster_time) }}</li>
                  <li> {{ bunch_data.message_count }} уникальных сообщений</li>
                  <li> {{ bunch_data.cluster_count }} кластеров</li>
                </ul>
              </div>
              <div class="card-body">
                <h5 class="card-title">Платформы</h5>
                <apexchart ref="theChart" type="donut" :options="chartOptionsDonut" :series="source_stats">
                </apexchart>

              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <!-- <div class="card-body"> -->
              <div class="card-body">
                <h5 class="card-title">Релевантные сущности</h5>
                <ner-button v-for="value in relevant_ners" :neText="value.text" :neId="value.id" :stream="getStream()"
                  :key="value.id"></ner-button>
              </div>
              <div class="card-body">
                <h5 class="card-title">Тэги</h5>
                <template v-for="(m, m_id) in taglist">
                  <tag-button :buttonText="m.tag_name" :tagId="m.tag_id" :bunchId="bunch_id" :isDeletable="true"
                    @btnClicked="removeTagFromList(m.tag_id)">
                  </tag-button>
                </template>
              </div>

              <div class="card-body">
                <h5 class="card-title">Тэги</h5>
                <b-button v-b-toggle="'collapse-2'" class="m-1">добавить тэг</b-button>
                <b-collapse id="collapse-2">
                  <div>
                    <b-form @submit.prevent="onSubmitTag" inline>
                      <b-form-input list="input-list-with-tags" v-model="inputTagName"></b-form-input>
                      <b-form-datalist id="input-list-with-tags" :options="knownTags"></b-form-datalist>
                      <b-button variant="info" type="submit" class="m-1">Save</b-button>
                    </b-form>
                  </div>
                </b-collapse>
              </div>


              <div class="card-body">
                <h5 class="card-title">Манипуляции с сюжетом</h5>
                <a class="btn btn-outline-secondary mt-2 mr-2 mb-2" @click="toggleNeighsOpened()">
                  <span v-if="neighsOpened">
                    Скрыть потенциальных соседей
                  </span>
                  <span v-else>
                    Показать потенциальных соседей
                  </span>
                </a>

                <div v-if="neighsOpened">
                  <div class="table-responsive bg-white shadow rounded">
                    <div style="max-height:20em; width:100%; overflow-y:scroll; overflow-x:auto;">
                      <table class="table mb-0 table-center text-secondary">
                        <thead>
                          <th scope="col" style="width:80%; text-align:center;"> Описание сюжета</th>
                          <th scope="col" style="width:15%; text-align:center;"> Схожесть</th>
                          <th scope="col" style="width:5%; text-align:center;"> Мерж</th>
                        </thead>
                        <tbody>
                          <template v-for="(row, row_id) in relevantBunches">
                            <tr style="background-color: #1f2d3d !important; color: #5a6d90 !important;">
                              <td style="word-wrap: break-word">
                                <a @click=getHistory(row.bunch_id)>
                                  {{ row.summary }}
                                </a>
                              </td>
                              <td>
                                <a style="pointer-events: auto;">{{ (row.iou).toFixed(2) }}</a>
                              </td>
                              <td>
                                <a @click="mergeBunches(row.bunch_id)">
                                  <git-merge-icon style="cursor:pointer;" class="fea icon-sm"></git-merge-icon>
                                </a>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="notification has-text-centered">
                  <b-button @click="checkConfirm"
                    :variant="!confOne ? 'warning' : (confOne && !confTwo) ? 'danger' : 'success'"
                    v-text="!confOne ? 'Расщепить сюжет' : (confOne && !confTwo) ? 'Точно расщепить?' : 'Расщеплено'"></b-button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 align-content-left justify-content-left">
          <a class="btn btn-outline-secondary mt-2 mr-2 mb-2" @click="toggleMsgsOpened()">
            <span v-if="msgsOpened">
              Скрыть примеры сообщений
            </span>
            <span v-else>
              Показать примеры сообщений
            </span>
          </a>
        </div>
        <div v-if="msgsOpened">
          <div class="table-responsive bg-white shadow rounded">
            <div style="max-height:20em; width: 100%; overflow-y:scroll; overflow-x:auto;">
              <table class="table mb-0 table-center text-secondary"
                style="font-size:small; width:100%; table-layout:fixed;" rules="groups">
                <colgroup>
                  <col span="1" style="width:90%;">
                  <col span="1" style="width:5%;">
                </colgroup>
                <tbody>
                  <template v-for="(m, m_id) in bunch_messages">
                    <tr style="background-color: #1f2d3d !important; color: #5a6d90 !important;">
                      <td style="word-wrap: break-word">{{ m.text }}</td>
                      <td>
                        <a style="pointer-events: auto;" :href=m.url target="_blank">ссылка</a>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12">
          <apexchart ref="theChart" type="scatter" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
      <div class="row m-12 justify-content-center align-items-center">
        <div class="col-12">
          <div class="table-responsive bg-white shadow rounded">
            <table class="table mb-0 table-center" style="width:100%;">
              <thead>
                <tr>
                  <th scope="col" style="width:13%; text-align:center;" @click="sort('date')">
                    Дата
                    <span v-if="currentSort === 'date'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>
                  <th scope="col" style="width:10%; text-align:center;">Число сообщений <br>(с дублями)</th>
                  <th scope="col" style="width:78%; text-align:center;">Название сюжета</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="x in sorted_history_list">
                  <tr>
                    <td colspan="3">
                      <table class="table mb-0 table-center" style="width:100%; font-size: small">
                        <template v-for="(y, ix) in x">
                          <tr v-if="ix == 0">
                            <td rowspan="100" style="width:13%; border-top: none !important; text-align:center;">
                              {{ y.date }}</td>
                            <td style="width:10%; border-top: none !important; text-align:center;">
                              {{ y.raw_count }}({{ y.dup_count }})</td>
                            <td style="width:78%; border-top: none !important;">{{ y.summary }}</td>
                          </tr>
                          <tr v-else>
                            <td style="width:10%; border-top: none !important; text-align:center;">
                              {{ y.raw_count }}({{ y.dup_count }})</td>
                            <td style="width:78%">{{ y.summary }}</td>
                          </tr>
                        </template>

                      </table>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store/store";

import { ArrowUpIcon, ArrowDownIcon, HelpCircleIcon, GitMergeIcon, DownloadCloudIcon } from "vue-feather-icons";
import EditableText from './editableText.vue';
import TagButton from './tagButton.vue';
import NerButton from './nerButton.vue';



export default {
  name: "topic-modelling-history",
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    HelpCircleIcon,
    EditableText,
    GitMergeIcon,
    TagButton,
    NerButton,
    DownloadCloudIcon

  },
  data() {
    return {
      dateOptions: { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
      history_list: [],
      currentSort: 'date',
      currentSortDir: 'asc',
      inputTagName: '',
      bunch_name: 'Загружаем...',
      msgsOpened: false,
      neighsOpened: false,
      data_loaded: false,
      bunch_id: -10,
      knownTags: [],
      relevantBunches: [],
      source_stats: [],
      series: [
        {
          name: "Сообщений в кластере",
          data: [],
          type: 'scatter',
        },
        {
          name: "Сообщений в сюжете за час",
          data: [],
          type: 'line',
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'История сюжета',
          align: 'left'
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'd MMM, HH:mm',
            datetimeUTC: false
          }
        },
        yaxis: [
          {
            seriesName: "Сообщений в кластере",
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Количество сообщений",
            },
          },
          {
            seriesName: "Сообщений в кластере",
            show: false,
          },
        ],
        tooltip: {
          custom: [
            function ({ series, seriesIndex, dataPointIndex, w }) {
              return '<div>' + '<span><b>' + series[seriesIndex][dataPointIndex] + '</b> сообщений в кластере</span>' + '<br>' + '<br>' +
                '<span>' + w.globals.initialSeries[seriesIndex].data[dataPointIndex].text + '</span></div>';
            },
            function ({ series, seriesIndex, dataPointIndex, w }) {
              return '<div class="arrow_box">' +
                '<span>' + series[seriesIndex][dataPointIndex] + ' сообщений за час' + '</span>' +
                '</div>';
            },

          ],

          shared: false,
          intersect: true,
          x: {
            format: 'd MMM, HH:mm',
            // show: false
          }
        },
      },
      chartOptionsDonut: {
        chart: {
          type: 'pie',
          zoom: {
            enabled: false
          },
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
        },
        colors: ['#0077FF', '#0088cc', '#ed812b', '#C13584', '#FF0000', '#1DA1F2', '#4267B2', '#595a00', '#029745', '#79867f'],
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        labels: ['VK', 'TG', 'OK', 'IG', 'YT', 'Twitter', 'FB', 'Dzen', 'Banki', 'Other',]
      },
      confOne: false,
      confTwo: false
    };
  },

  mounted() {
    this.getData();
    this.collectTags();
    // this.stream= this.$route.params.stream;

  },
  methods: {
    getStream() {
      return this.$route.params.stream;
    },
    collectTags() {
      this.axios({
        method: "get",
        url: this.getHostname(this.$route.params.stream) + "/plot-modelling/collect-tags",
      }).then((res) => {
        this.knownTags = res.data.data.map(x => x.tag_name);
      })

    },
    onSubmitTag() {
      console.log('submitted ' + this.inputTagName);
      let requestData = {
        bunch_id: this.bunch_id,
        tag_name: this.inputTagName,
        user_id: store.state.user_id,
      };

      this.axios({
        method: "post",
        url: this.getHostname(this.$route.params.stream) + "/plot-modelling/attach-tag",
        data: requestData,
      }).then((res) => {
        this.new_tag_id = res.data.tag_id
        this.taglist.push({ 'tag_id': res.data.tag_id, 'tag_name': this.inputTagName });

        console.log('tag attached');
        this.inputTagName = '';

      })
      this.$root.$emit('bv::toggle::collapse', 'collapse-2');
      // this.knownTags.filter()

    },
    saveTitleEdit(newValue, bunchId) {
      let requestData = {
        bunch_id: parseInt(bunchId),
        text_data: newValue
      };
      this.axios({
        method: "post",
        url: this.getHostname(this.$route.params.stream) + "/plot-modelling/change-bunch-name",
        data: requestData,
      }).then((res) => {
        this.bunch_name = newValue;
        console.log('title updated');
      })
    },
    prettyDt(date) {
      return new Date(date).toLocaleTimeString('ru-RU', this.dateOptions);
    },
    saveSummaryEdit(newValue, bunchId) {
      let requestData = {
        bunch_id: parseInt(bunchId),
        text_data: newValue
      };
      this.axios({
        method: "post",
        url: this.getHostname(this.$route.params.stream) + "/plot-modelling/change-bunch-summary",
        data: requestData,
      }).then((res) => {
        this.bunch_auto_summary = newValue;
        console.log('summ updated');
      });
    },
    toggleOnLoad() {
      this.$refs.theChart.toggleSeries("Сообщений в кластере")
    },
    getHostname(stream) {
      let basic_url = ''
      if (stream == "SberBA") {
        basic_url = basic_url + this.$hostname_ba
      } else {
        basic_url = basic_url + this.$hostname_ba_macro
      }
      return basic_url;
    },
    getData() {
      this.data_loaded = false;
      let requestData = {
        bunch_id: parseInt(this.$route.params.bunchId)
      };
      this.axios({
        method: "post",
        url: this.getHostname(this.$route.params.stream) + "/plot-modelling/get-bunch-history",
        data: requestData,
      }).then((res) => {
        this.bunch_data = res.data.bunch_data;
        this.history_list = res.data.cluster_stats;
        this.msg_bins = res.data.msg_stats;
        this.bunch_messages = res.data.bunch_messages;
        this.bunch_id = this.bunch_data.bunch_id;
        this.bunch_name = this.bunch_data.bunch_name;
        this.bunch_auto_summary = this.bunch_data.bunch_auto_summary;
        this.taglist = this.bunch_data.tags;
        this.relevant_ners = res.data.relevant_ners;
        this.relevantBunches = res.data.neighbor_bunches;
        this.source_stats = res.data.source_stats;

        console.log(res);
        let cluster_plot_data = [];
        for (let i = 0; i < this.history_list.length; i++) {
          for (let i_inner = 0; i_inner < this.history_list[i].length; i_inner++) {
            cluster_plot_data.push({
              x: this.history_list[i][i_inner].date,
              y: this.history_list[i][i_inner].raw_count,
              text: this.history_list[i][i_inner].summary
            })
          }
        }
        this.series = [
          {
            name: "Сообщений в кластере",
            data: cluster_plot_data,
            type: 'scatter',
          },
          {
            name: "Сообщений в сюжете за час",
            data: this.msg_bins,
            type: 'bar',
          },
        ];
        if ([null, '', ' ', undefined].includes(this.bunch_data.bunch_name)) {
          this.bunch_name = `Сюжет №${this.bunch_id}`;
        }
        this.data_loaded = true;
      });
    },
    getHistory(bunch_id) {
      let current_url = window.location.href;
      window.open(current_url.replace(this.bunch_id.toString(), bunch_id.toString()), '_blank');
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    mergeBunches(bunch_id) {
      console.log('merging the bunch ' + bunch_id + '!');
      this.axios({
        method: "post",
        url: this.getHostname(this.$route.params.stream) + "/plot-modelling/merge-two-bunches",
        data: {
          bunch_id_a: this.bunchId,
          bunch_id_b: bunch_id,
          new_name: this.bunch_name,
          new_summary: this.bunch_data.bunch_user_summary,
          user_id: store.state.user_id,
        },
      }).then((res) => {
        console.log('merged the bunch ' + bunch_id + '!');
        this.getData();
      })


    },
    toggleMsgsOpened() {
      this.msgsOpened = ~this.msgsOpened;
    },
    toggleNeighsOpened() {
      this.neighsOpened = ~this.neighsOpened;
    },
    checkConfirm() {
      if (this.confOne === false) {
        this.confOne = true
      } else {
        this.confTwo = true;
        console.log('split started');

        this.axios({
          method: "post",
          url: this.getHostname(this.$route.params.stream) + "/plot-modelling/split-bunch",
          data: {
            bunch_id: this.$route.params.bunchId,
            user_id: store.state.user_id
          },
        }).then((res) => {
          console.log('bunch splitted');
          this.getData();
        })



      }

    },
    getExcel() {
      let requestData = {
        bunch_id: this.bunch_id
      };
      this.$vs.loading({
        container: '#div-with-loading',
        scale: '0.45',
      });
      this.axios({
        method: 'post',
        url: this.getHostname(this.$route.params.stream) + "/get-bunch-history-data-file",
        data: requestData,
        responseType: "blob",
      }).then((response) => {

        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        const blob = new Blob(
          [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        // let datetime = new Date().toLocaleTimeString() + '_' + new Date().toLocaleDateString().replace(/\./g, '-');
        let curtime = new Date();
        let datetime = curtime.toLocaleDateString('ru-RU').replace(/\./g, '-') + ' ' + curtime.toLocaleTimeString('ru-RU').replace(/:/g, '-');
        // let datetime = curtime.toLocaleTimeString() + '_' +curtime.toLocaleDateString().replace(/\./g, '-');
        aEle.download = 'Report on ' + this.bunch_id + ' bunch at ' + datetime + '.xlsx';  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object

      })
    },
    removeTagFromList(tagId) {

      console.log('btn clicked');
      let requestData = {
        bunch_id: this.$route.params.bunchId,
        tag_id: tagId
      };
      this.axios({
        method: "post",
        url: this.getHostname(this.$route.params.stream) + "/plot-modelling/detach-tag",
        data: requestData,
      }).then((res) => {
        console.log('tag removed');
        this.taglist = this.taglist.filter(x => x.tag_id != tagId);
        this.getData();
        console.log(this.taglist);
        console.log('taglist updated');
      })
    }
  },
  computed: {
    sorted_history_list: function () {
      let mod = this.currentSortDir == 'desc' ? -1 : 1;
      return this.history_list.sort((a, b) => (Date.parse(a[0].date) - Date.parse(b[0].date)) * mod);
    }

  },
};
</script>

<style>
svg>text {
  fill: white;
}

.apexcharts-tooltip.apexcharts-theme-light {
  background: rgb(31 45 61)
}

.apexcharts-text tspan {
  fill: white;
}

.apexcharts-menu {
  background: rgb(31 45 61);
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: rgb(187, 219, 255)
}

.tooltip-container {
  max-width: 300px;
}

.tooltip-text {
  flex-wrap: wrap;
}

.apexcharts-tooltip {
  white-space: pre-wrap !important;
  max-width: 200px;
}

.apexcharts-legend-text {
  color: white !important;
}

.button {
  transition: background-color 0.5s ease-out;
}
</style>

get-bunch-history-data-file